.filterWrapper{
    position: sticky;
    top: 10px;

    label{
        font-family: 'Effra';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    input[type="radio"]:checked+label {
        color: #333092
      }
}

.filterBox {
    padding: 20px;
    border: 1px solid #D7D7D7;
    margin-bottom: 20px;
}

.accordion-button {
    padding:0px !important;
    font-family: "Effra";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #231f20;

    span{
        font-weight: 400;
        text-transform: capitalize;
    }
}

.accordion-button:focus, .accordion-button:not(.collapsed){
    border-color: white !important;
    box-shadow: none !important;
    background-color: white !important;
    color:inherit !important
}

.accordion-body {
    padding-left: 0px !important;
}

.accordion-item {
    border: none !important;
}

button.removeFilters {
    background: none;
    color:black;
    text-align: left;
    justify-content: left;
    padding-left: 0;
    text-decoration: underline;
    font-size: 12px;
    width: 25%;
}

button.removeFilters:hover {
   color:#cd007a;

}

.filterBox .accordion-body {
    max-height: 500px;
    overflow-y: auto;
}