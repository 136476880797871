.login {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    margin-top: 40px;
    border: 1px solid #cd007a;
    text-align: center;
  

      a {
        text-decoration: none !important;
      }
  
      label,
      span {
        font-family: "Effra";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #4a4a4a;
        padding: 5px;
        width: 20%;
      }
      input {
        width: 60%;
      }
  
      .submit {
          display: flex;
          align-items: center;
          justify-content: center;
        }

  }