.user-navbar {
    background-color: #FFF;
    padding: 20px !important;
	margin: auto;
	justify-content: space-between !important;

    .navGreeting {
        font-size: 24px;
    }
    .navbar-content {

        a{
            margin-right: 40px;
            color:#00AEEF;
            text-decoration: none;
            font-family: "Effra";
            font-size: 24px;
        }

        a:hover{
            cursor: pointer;
            text-decoration: underline;
        }

    }

}

.user-navbar.admin {
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    border-bottom: 1px solid black;
}