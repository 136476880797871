@use "../../App.scss" as app;
.hero {
  // background new url
  background-image: url("../../img/heroBanner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
}

.customer-cta {
  display: flex;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-top: 40px;
  border: 1px solid #cd007a;

  p {
    font-size: 12px;
  }

  .login {
    text-align: center;
    padding: 20px;
    margin: 0 auto;

    label,
    span {
      font-family: "Effra";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #4a4a4a;
      padding: 5px;
      width: 100%;
      text-align: left;
    }
    input {
      width: 100%;
    }

    .submit {
      margin: 0 auto;
      width: 85%;
    }
  }

  .vr {
    color: black;
    opacity: 0.75;
    padding: 0.5px;
  }

  hr{
    color: black;
    opacity: 0.75;
    padding: 0.5px;
  }
}


button,
input[type="submit"] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border: none;
  //width: 148px !important;
  height: 40px;
  border-radius: 2rem;

  svg {
    margin-right: 5px;
    font-size: 20px;
  }
}

button.new, button.edit {
  width:148px;
}

.new{
  background: white;
  color: #cd007a;
  border: solid 1px #cd007a;
  }

  .edit{
    background: #00AEEF;
    color: white;
  }

  .content-card{
    max-width: 500px;
    border: solid 1px #333092;
    border-radius: 2rem;
    height: 175px;
    margin: 15px;
    background:linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);;
  }

  .card-icon{
    font-size: 2.5em;
  }

  .card-title{
    display: flex;
    align-items: center;
    font-size: 2em;
    padding-top: 5px;
  }
