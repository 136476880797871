@font-face {
	font-family: EffraBold;
	src: local("EFFRA_STD_BD"), url("./fonts/EFFRA_STD_BD.TTF") format("truetype");
}
@font-face {
	font-family: EffraItalic;
	src: local("EFFRA_STD_IT"), url("./fonts/EFFRA_STD_IT.TTF") format("truetype");
}
@font-face {
	font-family: EffraLight;
	src: local("EFFRA_STD_LT"), url("./fonts/EFFRA_STD_LT.TTF") format("truetype");
}
@font-face {
	font-family: EffraLightItalic;
	src: local("EFFRA_STD_LTIT"), url("./fonts/EFFRA_STD_LTIT.TTF") format("truetype");
}

@font-face {
	font-family: Effra;
	src: local("EFFRA_STD_RG"), url("./fonts/EFFRA_STD_RG.TTF") format("truetype");
}


body {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.max-width{
	max-width: 1200px;
}

.callout-box {
	padding: 43% 1.5rem 1.5rem 1.5rem !important;
	width: 100%;
}
@media (min-width: 768px) {
	.callout-box {
		padding: 2.5rem !important;
		border-radius: 12px;
		background-color: white;
		width: fit-content;
		max-width: 36%;
		margin-left: 7%;
	}
}

h1 {
	margin-bottom: 1rem !important;
}

main a {
	color: #cd007a;
}