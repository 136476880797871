@use "../../App.scss" as app;

.hero {
  // background new url
  background-image: url("../../img/heroBanner.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {

  .hero {
    background-size: cover;
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.faqBoundary {
  border: 1px solid pink;
}

.containerFaqs {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;

  h2 {
    margin: 1.5rem 0;
  }
}

.lineFaqs {
  border-bottom: 1px solid #4A4A4A;
}

.customer-cta {
  display: flex;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-top: 40px;
  border: 1px solid #707070;
  border-radius: 12px;

  p {
    font-size: 12px;
  }

  .customer {
    text-align: center;
    padding: 20px;
    height: 100%;

    .home-block {
      &.get-started-customer {
        height: 245px;
      }
      height: 220px;
      position: relative;

      .submit {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    a {
      text-decoration: none !important;
    }
  }

  .login {
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    .home-block {
      .submit {
        input {
          width: 85%;
        }
      }
    }

    label,
    span {
      font-family: "Effra";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #4a4a4a;
      padding: 5px;
      
    }
    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .not-customer {
    text-align: center;
    padding: 20px;
    display: block;
    border: none;
    margin-top: 0px;
    height: 100%;
  }

  .vr {
    color: #707070;
    opacity: 0.75;
    padding: 0.5px;
  }

  hr {
    color: black;
    opacity: 0.75;
    padding: 0.5px;
  }
}

.not-customer {
  display: flex;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-top: 40px;
  border: 1px solid #cd007a;
  justify-content: space-between;

  .home-block {
    height: 220px !important;
    position: relative !important;

    .submit {
      position: absolute!important;
      bottom: 0 !important;
      width: 100% !important;
      display: flex;
      justify-content: center;
    }
  }
  a {
    text-decoration: none !important;
  }

}

.companySelect {
  width:70%;
}

.errorText {
  font-size: 15px !important;
  color: red;
  font-weight: 500;
}
.consent {
  margin: 10px 0;
}

.noBorder{
  border:none !important;
}

button,
input[type="submit"] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border: none;
  height: 40px;
  background: #cd007a;
  border-radius: 2rem;
  color: white;

  svg {
    margin-right: 5px;
    font-size: 20px;
  }
}

button:disabled, input[type='submit']:disabled{
  opacity: 0.3;

}

button.secondary {
  color: #cd007a;
  background-color: white;
  border: 1px solid #cd007a;
  border-radius: 2rem;
}


.terms {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
  margin-top: 30px;

  p {
    font-size: 12px;
  }
}

.terms:last-of-type {
  margin-bottom: 40px;
}

.textAlignCenter {
  text-align: center;
}

.terms .MuiButtonBase-root.MuiAccordionSummary-root,
.terms .MuiAccordionDetails-root  {
  padding: 0;
}

.terms .MuiAccordionSummary-content {
  flex-grow:0;
  justify-content: left;
}