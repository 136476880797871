.faq {

	.form label {
		margin-top: 32px;
		display: block;
		margin-left: 20px;
	}

	.form input,
	.form textarea {
		display: block;
		width: 200px;
		margin-top: 5px;
		padding: 5;

		@media screen and (max-width:991px) {
			width: 90%;
		}
	}

	.border {
		border: 1px solid #ccc;
	}

}