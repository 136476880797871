.editEmail {
    width: 100%;
    margin-bottom: 50px;


    .registerForm {

        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        padding: 20px;
        padding-bottom: 75px;

        .header {
            background-color: #00aeef;
            border-bottom: 1px solid #4a4a4a;

            p {
                font-family: 'EffraBold';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
                color: #FFFFFF;
                padding-top: 15px;
            }
        }

        .body {

            input,
            select {
                width: 100%;
            }

            input[type='checkbox'] {
                width: 3%;
                margin-right: 5px;
            }

            label,
            span {
                font-family: 'Effra';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #4A4A4A;
                margin-top: 20px;
            }

            label:after {
                color: red;
                content: '*';
                padding-left: 5px;
            }

            select {
                padding: 5px
            }
        }

        .divider {
            margin-top: 50px;
            margin-bottom: 5px;
            font-size: 18px !important;
        }

        .divider:first-of-type {
            margin-top: 0px;
        }

        .submit {
            position: fixed;
            bottom: 0;
            background-color: #DBDBDB;
            width: 100%;
            left: 0;
            display: flex;
            padding: 15px;
            align-items: start;

            button {
                padding: 10px 30px;
                border: none;
                height: 40px;
                background: #00AEEF;
                border-radius: 2rem;
                color: white;

                svg {
                    margin-right: 5px;
                    font-size: 20px;
                }
            }

            button.secondary {
                color: #EC1975;
                background-color: white;
                border: 1px solid#EC1975;

            }
        }
    }
}