.itemhistorydate{
    font-size: 20px;
}

.itemhistorydesc{
    font-size: 18px;
}

.itemhistoryuser {
    font-weight: 600;
}

.itemHistoryBody {
    max-height: 500px;
    overflow-y: auto;
}