.footer {
    display: flex;
    background-color: #4A4A4A40;
    padding:25px;
    .footer-content {
        width: 80%;

        a{
            margin-right: 40px;
            color:#4A4A4A;
            text-decoration: none;
            font-family: "Effra";
            font-size: 15px;

            @media screen and (max-width: 991px ) {
                margin-right: 10px;
            }
        }

        a:hover{
            cursor: pointer;
            text-decoration: underline;
        }

        .footer-nav{
            margin-bottom: 10px;
        }

        span{
            font-size: 13px;
            font-family: "Effra";
        }
    }
    .footer-socials {
        display: flex;
        width: 20%;
        justify-content: center;

        @media screen and (max-width: 991px ) {
           width: 100%;
           justify-content: left;
        }

        a{
            margin-left: 20px;
            margin-top:20px;
           
        }
        a:first-of-type {
            @media screen and (max-width: 991px ) {
                margin-left: 0px;
             }
        }
        a:hover{
            svg {
                height: 25px;
                width: 25px;
            }
        }
        svg{
            color:#4A4A4A;
            width: 22px;
            height: 22px;
        }
    }
}