.register {
    width: 100%;


    .registerForm {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        border: 1px solid #4a4a4a;


        .header {
            background-color: #00aeef;
            border-bottom: 1px solid #4a4a4a;

            p {
                font-family: 'EffraBold';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
                color: #FFFFFF;
                padding-top: 15px;
            }
        }

        .body {
            padding: 20px;

            input,
            select {
                width: 100%;
            }

            input[type='checkbox'] {
                width: 3%;
                margin-right: 5px;
            }

            label, span {
                font-family: 'Effra';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #4A4A4A;
            }

            label:after {
                color: red;
                content: '*';
                padding-left: 5px;
            }

            select {
                padding: 5px
            }
        }

        .submit {
            display: flex;
            justify-content: right;
            padding:15px;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 30px;
                border:none;
                width: 148px;
                height: 40px;
                background: #00AEEF;
                border-radius: 2rem;
                color: white;

                svg {
                    margin-right: 5px;
                    font-size: 20px;
                }
            }
        }
    }

    .thankyou {
        padding:25px;

        span {
            font-weight: bold;
        }
    }
}

.logoImage {
    max-width: 150px;
    height: auto;
    width: auto;
}

.companyNotes {
    width: 100%;
    min-height: 300px;
}